// Here you can add other styles
.full-screenable-node {
    background-color: white;
    text-align: center;
    // margin: 10px;
    padding-bottom: 50px;
    height: 100%;
}

:-webkit-full-screen .full-screenable-node{
  background-color: white;
  text-align: center;
  // margin: 10px;
  padding-bottom: 50px;
  height: 100%;
}

:-webkit-full-screen .full-screenable-node p{
  color:white;
}

// .full-screenable-node ul {
//     list-style: none;
// }

.grouting-card ul{
  list-style: none;
}

#tito-header .header-button {
  float: right;
  width: 30px;
  height: 30px;
  padding: 2px;
  transform: translate(-50px,-5px);
  position: absolute;
  right:0;
}

#tito-header .title {
  position:relative;
}
#tito-body .input {
  float: left;
}
#tito-body .btn {
  float: right;
}

.red_dot {
  height: 15px;
  width: 15px;
  background-color: rgb(251, 0, 0);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 2px;
}

.yellow_dot {
  height: 15px;
  width: 15px;
  background-color: rgb(226, 211, 0);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 2px;
}

.input-group {
  justify-content: center;
 
}

.input-group ul {
    list-style-type: none;
}

.suggestionsContainerOpen {
  border: '1px solid #aaa';
  backgroundColor: '#fff';
  fontFamily: 'Helvetica, sans-serif';
  fontWeight: 300;
  fontSize: 16;
  borderBottomLeftRadius: 4;
  borderBottomRightRadius: 4;
}

.TunnelBoard {
 
  #tito-body table {
  font-size: 14px;
  .tag-out-btn {    
    position: absolute;
    margin-top: -8px;
    right: 10%;
  }
  
  &.medium-capacity {
    font-size: 12px; 
    td { padding-top: 10px; padding-bottom: 10px; }     
    .tag-out-btn {    
      position: absolute;
      margin-top: -8px;
      right: 10%;
    }}
  &.high-capacity { 
    font-size: 10px;
    td { padding-top: 8px; padding-bottom: 8px; }
    .tag-out-btn {    
      position: absolute;
      margin-top: -8px;
      right: 10%;
    }}
 }

 .actions {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;

   button:not(:first-child) {
     margin-left: 10px;
   }
 }

 .fullscreen-enabled {
  .actions {
    position: absolute;
    bottom: 5%;
  }
 }

}